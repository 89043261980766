<template>
	<div class="main-content">
		<ktv-breadcrumb :title="`${opsiDisplay}` + ' Form'" page="Form" folder="Transaction" />
		<div>
			<ktv-back @click="backToList()" />
			<b-row class="mt-3">
				<b-col cols="8">
					<ktv-accor title="Transaction Data" :footer="opsiDisplay !== 'View'">
						<template #content>
							<b-row v-show="!loading" class="tab-content">
								<b-col>
									<b-form-group class="mt-2" label="Transaction ID">
										<b-form-input v-model="form.TransactionID" type="text" readonly />
									</b-form-group>
									<b-form-group class="mt-2" label="Transaction Code">
										<b-form-input v-model="form.TransactionCode" type="text" readonly />
									</b-form-group>
									<b-form-group class="mt-2" label="Transaction Date">
										<b-form-input v-model="form.DateCreated" type="text" readonly />
									</b-form-group>
									<b-form-group class="mt-2" label="Transaction Type">
										<b-form-input v-model="form.TransactionType" type="text" readonly />
									</b-form-group>
									<b-form-group class="mt-2" label="SME Name">
										<b-form-input v-model="form.SMEName" type="text" readonly />
									</b-form-group>
									<!-- <b-form-group class="mt-2" label="Customer Name">
										<b-form-input v-model="form.CustomerName" type="text" readonly />
									</b-form-group> -->
								</b-col>
								<b-col>
									<b-form-group class="mt-2" label="Quantity Items">
										<b-form-input v-model="form.Qty" type="text" readonly />
									</b-form-group>
									<b-form-group class="mt-2" label="Discount">
										<b-form-input v-model="form.Discount" type="text" readonly />
									</b-form-group>
									<b-form-group class="mt-2" label="Price Total">
										<b-form-input v-model="form.PriceTotal" type="text" readonly />
									</b-form-group>
									<b-form-group class="mt-2" label="Payment Method">
										<b-form-input v-model="form.PaymentMethodName" type="text" readonly />
									</b-form-group>
									<b-form-group class="mt-2" label="Account Number">
										<b-form-input v-model="form.AccountNumber" type="text" readonly />
									</b-form-group>
									<b-form-group class="mt-2" label="Status Payment">
										<b-form-input v-model="form.StatusPayment" type="text" readonly />
									</b-form-group>
								</b-col>
								<b-col cols="12" class="mt-3">
									<b-card
										border-variant="primary"
										header="Transaction Product List"
										header-bg-variant="primary"
										header-text-variant="white"
										align="left"
										no-body
									/>
									<ktv-table :line-numbers="true" :filter="false" :rows="rowsItems" :columns="columnsItems" />
								</b-col>
							</b-row>
						</template>
					</ktv-accor>
				</b-col>
			</b-row>




			<!-- <b-card class="mt-3" border-variant="primary" header="Transaction Data" header-bg-variant="primary" header-text-variant="white" align="left">
				<vcl-list v-show="loading" class="mt-4" />
				<vcl-list v-show="loading" class="mt-4" />
			</b-card> -->
		</div>
	</div>
</template>
<script>
	import { KtvTable } from "@/components"
	import { mapActions, mapGetters } from "vuex"

	export default {
		metaInfo: {
			title: "Transaction Form",
		},
		components: {
			KtvTable,
		},
		data() {
			return {
				loading: false,
				// form : {
				// 	TransactionID: "6",
				// 	TransactionCode: "JL/20210903/XXI/IX/1630655226026",
				// 	DateCreated: "2021-09-03 07:47:00",
				// 	TransactionType: "SELL",
				// 	SMEName: "Andi",
				// 	CustomerName: "Rukmana",
				// 	Qty: "1",
				// 	Discount: "0.00",
				// 	PriceTotal: "170000.00",
				// 	PaymentMethodName: "Tunai",
				// 	StatusPayment: "Paid",
				// 	AccountNumber: "",

				// 	"CustomerSupplierUid": "xGWPadNX4JO",
				// 	"Price": "170000.00",
				// 	"Payment": "170000.00",
				// 	"Change": "0.00",
				// 	"ReffTransactionCode": "null",
				// 	"PaymentMethodID": "2",
				// 	"ReturReason": "",
				// 	"Reprint": "0",
				// 	"StatusCode": "",
				// 	"CreatedBy": "nannisa061@gmail.com",
				// 	"DateUpdated": "2021-09-03 07:47:21",
				// 	"LastModifiedBy": "null",
				// 	"mdrFee": "0.0",
				// 	"ServiceFee": "0",
				// 	"AccountName": "",
				// 	"CommodityID": "49",
				// 	"EntityID": null,
				// 	"FeeDisburse": "Paid",
				// 	"TotalDisburse": "Paid",
				// 	"inquiry_id": "2647797755866519511",
				// 	"service_code": "003",
				// 	"trace_id": null,
				// 	"CreatedTo": "nannisa061@gmail.com"
				// },
				form: {
					TransactionID: "",
					TransactionCode: "",
					DateCreated: "",
					TransactionType: "",
					SMEName: "",
					CustomerName: "",
					Qty: "",
					Discount: "",
					PriceTotal: "",
					PaymentMethodName: "",
					StatusPayment: "",
					AccountNumber: "",
					CustomerSupplierUid: "",
					Price: "",
					Payment: "",
					Change: "",
					ReffTransactionCode: "",
					PaymentMethodID: "",
					ReturReason: "",
					Reprint: "",
					StatusCode: "",
					CreatedBy: "",
					DateUpdated: "",
					LastModifiedBy: "",
					mdrFee: "",
					ServiceFee: "",
					AccountName: "",
					CommodityID: "",
					EntityID: "",
					FeeDisburse: "",
					TotalDisburse: "",
					inquiry_id: "",
					service_code: "",
					trace_id: "",
					CreatedTo: "",
				},
				rowsItems: [
					{
						ProductName: "Pupuk Padi",
						Qty: "2",
						ProductPrice: "12.000",
						Discount: "0",
						Total: "24.000",
					},
					{
						ProductName: "Pestisida",
						Qty: "1",
						ProductPrice: "10.000",
						Discount: "0",
						Total: "10.000",
					},
				],
				columnsItems: [
					{
						label: "Product Name",
						field: "ProductName",
						thClass: "text-center",
						tdClass: "text-left",
					},
					{
						label: "Quantity",
						field: "Qty",
						thClass: "text-center",
						tdClass: "text-center",
					},
					{
						label: "Price",
						field: "ProductPrice",
						thClass: "text-center",
						tdClass: "text-right",
					},
					{
						label: "Discount",
						field: "Discount",
						thClass: "text-center",
						tdClass: "text-right",
					},
					{
						label: "Total",
						field: "Total",
						thClass: "text-center",
						tdClass: "text-right",
					},
				],
			}
		},
		computed: {
			...mapGetters({
				opsiDisplay: "TRANSACTION/getOpsiDisplay",
				error: "TRANSACTION/getError",
			}),
		},
		watch: {
			error(val) {
				if (val != null) {
					this.makeToast("Error", "danger", val.message)
				}
			},
		},
		async mounted() {
			await this.FetchData()
		},
		methods: {
			...mapActions({
				clearData: "TRANSACTION/clearData",
				getDataDetail: "TRANSACTION/getDataDetail",
			}),
			async FetchData() {
				if (this.opsiDisplay === "View" || this.opsiDisplay === "Update") {
					const res = await this.getDataDetail()
					this.form = res.basic_data
					this.rowsItems = res.detail_data
					this.loading = false
				}
			},
			backToList() {
				this.$router.back()
			},
		},
	}
</script>
